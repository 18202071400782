import React, { useCallback } from "react";
import "./App.scss";
import Obfuscate from "react-obfuscate";
// import Particles from 'react-particles-js';
// import Particles from 'react-particles-js';
// import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import ReactGA from "react-ga";
import "../src/twitterfeed";
// import auth from './auth.ts'; // Sample authentication provider

const trackingId = "UA-1833250-53";
ReactGA.initialize(trackingId);
ReactGA.pageview("/"); // Send homepage pageview
// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

const particlesOptions = {
	particles: {
		number: {
			value: 80,
			density: {
				enable: true,
				value_area: 800,
			},
		},
	},
};

// const particlesInit = useCallback(async engine => {
//   console.log(engine);
//   // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
//   // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
//   // starting from v2 you can add only the features you need reducing the bundle size
//   await loadFull(engine);
// }, []);

// const particlesLoaded = useCallback(async container => {
//   await console.log(container);
// }, []);

// import {TweenMax, Back, Sine} from 'gsap';
// var sass = require('node-sass');
// var compass = require('compass-importer')

function EmailAddress() {
	return (
		<Obfuscate
			email="2024@jamesfawcett.co.uk"
			headers={{
				// cc: "dade@zero-cool.af",
				subject: "Contact from JamesFawcett.com",
				body: "Hi James",
			}}
		/>
	);
}

function OutputTwitterFeed() {
	var twitterClass = "twitter-feed";

	return (
		<div id={twitterClass} className={twitterClass}>
		</div>
	);
}

function App() {
	return (
		<div className="App">
			{/*
          <svg viewBox="0 0 1600 1600" preserveAspectRatio="xMidYMid slice">
        <defs>
          <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0" colorInterpolation="sRGB">
            <stop id="stop1a" offset="0%" stopColor="#12a3b4"></stop>
            <stop id="stop1b" offset="100%" stopColor="#ff509e"></stop>
          </linearGradient>
          <linearGradient id="grad2" x1="0" y1="0" x2="1" y2="0" colorInterpolation="sRGB">
            <stop id="stop2a" offset="0%" stopColor="#e3bc13"></stop>
            <stop id="stop2b" offset="100%" stopColor="#00a78f"></stop>
          </linearGradient>
        </defs>
        <rect id="rect1" x="0" y="0" width="1600" height="600" stroke="none" fill="url(#grad1)"></rect>
        <rect id="rect2" x="0" y="0" width="1600" height="600" stroke="none" fill="url(#grad2)"></rect>
      </svg>
      */}

			{/* <Particles className="particles"  /> */}
			{/* <Particles /> */}
			{/* <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#0d47a1",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 6,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> */}

			<header className="App-header">
				<div className="profile-holder">
					<div className="profile">
						<a href="https:\/\/www.jamesfawcett.co.uk">
							<img
								alt="James Fawcett"
								width="100"
								height="100"
								className="profile__me"
								src="james-fawcett.png"
							/>
							<h1 className="profile__name">James Fawcett</h1>
							<h2 className="profile__job">
								Advanced Wordpress Developer/PHP/Javascript
							</h2>
						</a>
						{/* <p>For a quick response, please get in touch via email:</p> */}

						<EmailAddress />
						<a
							className=""
							href="https:\/\/jamesfawcett.co.uk"
							target="_blank"
							rel="noopener noreferrer"
						>
							<br />
							jamesfawcett.co.uk
						</a>
						<br />
						<br />
						{/* <form action="https:\/\/www.paypal.com\/cgi-bin\/webscr" method="post" target="_top">
            <input type="hidden" name="cmd" value="_donations" />
            <input type="hidden" name="business" value="contact@jamesfawcett.co.uk" />
            <input type="hidden" name="lc" value="GB" />
            <input type="hidden" name="item_name" value="Help with server costs" />
            <input type="hidden" name="amount" value="2.00" />
            <input type="hidden" name="currency_code" value="GBP" />
            <input type="hidden" name="no_note" value="0" />
            <input type="hidden" name="bn" value="PP-DonationsBF:btn_donate_SM.gif:NonHostedGuest" /> */}
						<span className="profile__donate-text">
							Any questions, please email or WhatsApp me anytime!
							<br />
							{/* <strong>Donate to server costs</strong> */}
						</span>
						{/* <input type="image" src="https:\/\/www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online." /> */}
						{/* <img alt="" border="0" src="https:\/\/www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1" /> */}
						{/* </form> */}
						<a aria-label="Chat on WhatsApp" href="https://wa.me/7478677588">
							{" "}
							<img alt="Chat on WhatsApp" src="whatsapp.svg" />
						</a>
					</div>
				</div>

				{/* <OutputTwitterFeed /> */}

				{/* <span className="site-footer">
					<a
						className="App-link"
						href="https:\/\/jamesfawcett.co.uk"
						target="_blank"
						rel="noopener noreferrer"
					>
						www.jamesfawcett.co.uk
					</a>
				</span> */}
			</header>
		</div>
	);
}

export default App;
